@tailwind base;
@tailwind components;
@tailwind utilities;

* { 
  /* font-family: 'Fira Sans', sans-serif;

  font-family: 'Source Sans Pro', sans-serif; */
}


